/* .side_bar_section > .btn img {
    filter: invert(1) !important;
}

.search_inner.open {
    height: 140px !important;
}

.btn.bonus {
    background-color: rgb(104 162 0) !important;
}

.footer-wrapper {
    background-color: rgb(104 162 0/40%) !important;
}

.footer-imgs img {
    filter: grayscale(1) drop-shadow(2px 4px 6px black) !important;
}

.jackpot_wrapper {
    background: rgb(40 61 2) !important;
    border-image: linear-gradient(0.25turn, #161816, #adb4b3, #161816) 2 0 2 0 !important;
} */
.footer_bottom_text{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
  color: #08d5a3;
    span{
        margin: 5px 0 5px 0;
    }
    a{
        text-underline: none;
        color: #8dead2;
    }
}
.footer-inner {
    margin-bottom: 50px;
}
.carousel-initialized{
    padding: 0 !important;
}
.bonus_wrapper{
    justify-content: end;
    .bonus_container{
        margin-right: 70px;
        img{
            height: 130% !important;
        }
    }
}
.btn.bonus {
    margin-top: 15px;
}
.footer-section.images{
    border-image: linear-gradient(0.25turn, #000f08, rgba(8, 213, 163, 0.27), #000f08) 1 0 1 0;
}
.jackpot_wrapper{
    background: rgba(1, 30, 20, 0.5);
}
.search_icon{
  @include recolor(#cfffe5)
}
.header-component{
    background: unset;
}
.header-logo{
    width: 10vw;
}
.btn.bonus {
    right: 7vw;
    bottom: auto;
    top: auto;
    height: 70%;
}
@media screen and (max-width: 600px) {
    .footer_bottom_text{
        margin-bottom: 250px;
    }
    .carousel-item{
        width: 100% !important;
    }
    .bonus_wrapper{
        justify-content: center;
        .bonus_container{
            margin-right: 0px;
        }
    }
    .btn.bonus {
        margin-top:40px;
        height: initial;
    }
    .bonus_wrapper{
        img{
            position: absolute;
            right: initial !important;
            top: 10%;
            height: 25%;
        }
    }
}